import React, {useState} from 'react';
import {Link} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//Bootstrap hamburger menu
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const axios = require('axios').default

const ProductsPage = () => {

    const [products, setProducts] = useState([])

    function handleSubmit (event) {
        event.preventDefault()

        axios.get(process.env.REACT_APP_SERVER_URL + "/trainer/products/" + event.target.value)
        .then((response) => {
            setProducts(response.data)
        })
        .catch((error) => {
            if (error) {
                console.log(error)
            }    
        })
    }


  return (
    <div>
        <Navbar className="bg-light shadow "  expand="md">
            <div className="container ms-4">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">

                    <Link to="/trainer" className='me-2 my-auto'>
                        <ArrowBackIcon fontSize="large" className="ms-3 mt-3 text-dark"/>
                    </Link>

            <Nav.Item>
                <li className="nav-item" >
                    <button onClick={handleSubmit} value="Rice" className="btn nav-link text-dark text-decoration-underline fs-5">Rice</button>
                </li>
            </Nav.Item>
            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Breads" className="btn nav-link text-dark text-decoration-underline fs-5">Breads</button>
                </li>
            </Nav.Item>
            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Eggs" className="btn nav-link text-dark text-decoration-underline fs-5">Eggs</button>
                </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Dairy" className="btn nav-link text-dark text-decoration-underline fs-5">Dairy</button>
                </li>
            </Nav.Item>

            
            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Butter" className="btn nav-link text-dark text-decoration-underline fs-5">Butter</button>
                </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item pe-2">
                    <button onClick={handleSubmit} value="Fruits" className="btn        nav-link text-dark text-decoration-underline fs-5">Fruits</button>
                    </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Fish" className="btn      nav-link text-dark text-decoration-underline fs-5">Fish</button>
                    </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Paper" className="btn         nav-link text-dark text-decoration-underline fs-5">Paper</button>
                    </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Baking" className="btn nav-link text-dark text-decoration-underline fs-5">Baking</button>
                </li>
            </Nav.Item>
            
            </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>



    <div className="container-fluid">
            <div className="row" style={{height: 500}}>
                {products && products.map((i) => (
                    <div className="hstack col-lg-3 col-md-4 col-sm-6" key={i._id}>
                        <div className="vstack container-fluid shadow-lg fs-5 mx-auto mt-4 mb-4" style={{borderRadius:20}} >
                            
                            {/* Aspect ratio of photo is maintained here */}
                            <div className=''>
                                <img src={__dirname + "images/"+i.image} className="container mx-auto rounded-3 mt-4" style={{maxwidth: 200}} alt=""/>
                            </div>
                            
                            <p className="text-start mb-0">{i.name}</p>
                            
                            <p className="mb-0">$ {(Number(i.price)).toFixed(2)} {i.quantifier} </p>

                            {i.expiry && <p className="text-start mb-0">Expiry: {i.expiry}</p>}
                            
                        </div>
                    </div>
                )) }
            </div>
        </div>
    </div>
  )
}

export default ProductsPage