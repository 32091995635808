import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux"
// import { AppContext } from "../../App";
import { addProduct } from "../../redux/studentRedux";
import {Link} from "react-router-dom";
import { Badge, Snackbar, Alert} from "@mui/material"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
//Bootstrap hamburger menu
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const axios = require('axios').default


function Marketplace () {

    const [products, setProducts] = useState([])
    // const [isDuplicate, setIsDuplicate] = useState(false)
    const [open, setOpen] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)

    const dispatch = useDispatch()

    const student = useSelector(state=>state.student)

    function handleSubmit(event) {
        event.preventDefault();
        
        axios.get(process.env.REACT_APP_SERVER_URL + "/student/marketplace/" +  event.target.value)
        .then((response) => {
            setProducts(response.data)  
        })
        .catch((error) => {
            if (error) {
                console.log(error);
            }
        })
    }
 
    function increment(i) {

        let items = products.map((product) => {   
            if (product._id === i) {
                return {
                    ...product,
                    quantity: product.quantity + 1
                }
            }
            return product;
        })
        setProducts(items);
    }
    
    function decrement (i) {
        
        let items = products.map((product) => {
            if (product._id === i) {
                if (product.quantity > 1)
                return {
                    ...product,
                    quantity: product.quantity - 1
                }
            }
            return product;
        })
        setProducts(items);
    }

    function handleAddToCart(product) {

        var duplicate = false;
        
        //if cart has no products, add products
        if (student.products.length === 0) {
            // eslint-disable-next-line
            products.map((i) => {
            if (i._id === product._id) {
                dispatch(addProduct(i))
                setSuccessOpen(true)                     
            }
            })
        }
        //if cart already has products,
        else {
            // eslint-disable-next-line
            student.products.map((i) => {
                //if that product is already in cart, do no add
                if (i._id === product._id) {
                    duplicate = true
                    setOpen(true)   
                }
            
            })
            //if product is not already in the cart, add to cart
            if (!duplicate) {
                // eslint-disable-next-line
                products.map((i) => {
                    if (i._id === product._id) {
                        dispatch(addProduct(i))
                        setSuccessOpen(true)
                    }
                })
            }
        }
    }

    return (
<div>

    <Navbar className="bg-light shadow "  expand="md">
        <div className="container ms-4">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">

            <Nav.Item>
                <li className="nav-item" >
                    <button onClick={handleSubmit} value="Rice" className="btn nav-link text-dark text-decoration-underline fs-5">Rice</button>
                </li>
            </Nav.Item>
            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Breads" className="btn nav-link text-dark text-decoration-underline fs-5">Breads</button>
                </li>
            </Nav.Item>
            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Eggs" className="btn nav-link text-dark text-decoration-underline fs-5">Eggs</button>
                </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Dairy" className="btn nav-link text-dark text-decoration-underline fs-5">Dairy</button>
                </li>
            </Nav.Item>

            
            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Butter" className="btn nav-link text-dark text-decoration-underline fs-5">Butter</button>
                </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item pe-2">
                    <button onClick={handleSubmit} value="Fruits" className="btn        nav-link text-dark text-decoration-underline fs-5">Fruits</button>
                    </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Fish" className="btn      nav-link text-dark text-decoration-underline fs-5">Fish</button>
                    </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Paper" className="btn         nav-link text-dark text-decoration-underline fs-5">Paper</button>
                    </li>
            </Nav.Item>

            <Nav.Item>
                <li className="nav-item">
                    <button onClick={handleSubmit} value="Baking" className="btn nav-link text-dark text-decoration-underline fs-5">Baking</button>
                </li>
            </Nav.Item>
            
            </Nav>
        </Navbar.Collapse>
      </div>

      <div className="hstack me-auto">
        <Badge color="error" overlap="circular" badgeContent={student.quantity} showZero >
            <Link to="/student/marketplace/checkout"> <ShoppingCartIcon fontSize="large" className="text-dark"/> </Link>
        </Badge>
        </div>
    </Navbar>


    <div className="container-fluid">
        <div className="row" style={{height: 520}}>

            {products && products.map((product) => (
                <div className="hstack col-lg-3 col-md-4 col-sm-6" key={product._id}>
                    <div className="vstack rounded-3 container shadow-lg fs-5 mt-4 mb-4" style={{borderRadius:20}}>

                        {/* Aspect ratio of photo is maintained this way */}
                        <div>
                            <img src={`/images/${product.image}`} style={{maxwidth:200}} className="container mx-auto rounded-3 mt-4" alt=""/>
                        </div>
                        
                        <p className="text-start mb-0">{product.name}</p>
                        <p className="text-start mb-0">{product.expiry && <p className="mb-0">Expiry: {product.expiry}</p>}</p>
                        <p className="mb-0">$ {(product.price.toFixed(2))} {product.quantifier} </p>
       
                            <div className="container hstack p-0">
                                <button onClick={() => decrement(product._id)} className="btn btn-light p-0"><RemoveCircleIcon fontSize="large" /></button>

                                <input onChange={(e) => { }} className="container text-center bg-light border-0 fs-5" name="quantity" value={product.quantity} />

                                <button onClick={() => increment(product._id)} className="btn p-0"><AddCircleIcon fontSize="large"/> </button>
                            </div>

                        <div className="hstack mx-auto">
                        <ShoppingCartIcon fontSize="large" />
                            
                            <button onClick={() =>{handleAddToCart(product)}} id="addToCart" className="btn text-decoration-underline fs-5">Add to Cart</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>

    <Snackbar autoHideDuration={2000} open={open} onClose={() =>{setOpen(false)}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
        <Alert onClose={() =>{setOpen(false)}} severity="error" sx={{ width: '100%' }}>
            Product is already in the cart
        </Alert>
    </Snackbar>

    <Snackbar autoHideDuration={2000} open={successOpen} onClose={() =>{setSuccessOpen(false)}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
        <Alert onClose={() =>{setSuccessOpen(false)}} severity="success" sx={{ width: '100%' }}>
            Product added to cart successfully
        </Alert>
    </Snackbar>

</div>
    )
    
}


export default Marketplace;




// <script>
//     function request_promo() {
//         var promoRequest = "yes";

//         fetch('/promoNoti', {
//                 method: "POST",
//                 credentials: "include",
//                 body: JSON.stringify(promoRequest),
//                 cache: "no-cache",
//                 headers: new Headers({
//                     "content-type": "application/json"
//                 })
//             })
//             .then(function(response) {
//                 response.json().then(function(data) {

//                     if (data.message == "promoTrue") {
//                         $('#promo_notif').show()
//                     }

//                 })
//             })

//     };
//     var checkPromo = setInterval(request_promo, 60000)

//     function promo(state) {
//         var promoNotRead = true
//         if (state == false) {
//             var promoNotRead = false
//             $('#promo_notif').hide();
//         }
//     };

//     function hamburger() {
//         var x = document.getElementById("myLinks");
//         if (x.style.display === "block") {
//             x.style.display = "none";
//         } else {
//             x.style.display = "block";
//         }
//     }
// </script>